var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppHeader'),(_vm.uType === '771')?_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md2":"","lg2":"","xl2":"","hidden-sm-and-down":""}},[_c('v-navigation-drawer',{staticClass:"fixedSidebar",attrs:{"height":"100vh","width":"100%","permanent":"","fixed":"","clipped":"","color":"white"},model:{value:(_vm.sideNav),callback:function ($$v) {_vm.sideNav=$$v},expression:"sideNav"}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-list',_vm._l((_vm.admins),function(main){return _c('v-list',{key:main._id},[(main.subMenu)?_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-list-group',{attrs:{"no-action":"","value":false},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm1":"","pl-2":""}},[_c('v-list-item-icon',[_c('v-img',{attrs:{"height":"20px","contain":"","src":main.src}})],1)],1),_c('v-flex',{class:{
                              mainbg6:
                                _vm.$route.path === main.name &&
                                _vm.$route.path === _vm.sub.route,
                            },attrs:{"xs6":"","sm8":"","text-center":"","pl-8":"","pt-5":""}},[_c('v-list-item-title',{staticStyle:{"font-size":"16px","color":"black","cursor":"pointer","font-family":"mainfont"}},[_vm._v(_vm._s(main.name)+" "+_vm._s(_vm.getUserType)+" ")])],1)],1)]},proxy:true}],null,true)},[_vm._l((main.subMenu),function(sub){return [_c('v-flex',{key:sub._id,class:{ mainbg6: _vm.$route.path === sub.route },staticStyle:{"text-decoration":"none"},attrs:{"xs12":"","text-right":"","pl-4":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs6":"","pa-2":"","text-left":""}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":sub.route}},[_c('span',{staticStyle:{"font-size":"15px","color":"black","cursor":"pointer","font-family":"mainfont"},style:(_vm.$route.path == sub.route
                                      ? {
                                          'font-family': 'mainfont',
                                          'font-size': ' 14px',
                                        }
                                      : {
                                          'font-family': 'mainfont',
                                          'font-size': ' 14px',
                                        })},[_vm._v(" "+_vm._s(sub.name)+" ")])])],1)],1)],1),_c('v-flex',{key:sub._d,attrs:{"xs12":""}})]})],2)],1)],1):_c('v-layout',{attrs:{"wrap":"","justify-end":"","pa-1":""}},[_c('v-flex',{class:{ mainbg5: _vm.$route.path === main.route },attrs:{"xs12":""}},[_c('v-list-item-group',{attrs:{"active-class":"activated"}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":main.route}},[_c('v-layout',{staticClass:"content",attrs:{"wrap":"","justify-end":""}},[_c('v-flex',{attrs:{"xs12":"","pr-3":"","sm2":"","align-self-center":"","text-center":""}},[_c('v-img',{attrs:{"height":"20px","contain":"","src":main.src}})],1),_c('v-flex',{attrs:{"xs12":"","sm8":"","pl-2":"","pt-1":"","align-self-center":"","text-left":""}},[_c('span',{staticStyle:{"font-size":"18px","color":"black","cursor":"pointer","font-family":"mainfont"},style:(_vm.$route.path === main.route
                                  ? {
                                      'font-family': 'mainfont',
                                      'font-size': '16px',
                                      color: 'black !important',
                                    }
                                  : {
                                      'font-family': 'mainfont',
                                      'font-size': '16px',
                                    })},[_vm._v(" "+_vm._s(main.name)+" ")])])],1)],1)],1)],1),_c('v-divider')],1)],1)}),1)],1)],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md10":"","lg10":"","xl10":"","pt-lg-0":""}},[_c('v-layout',{style:(_vm.$route.name == 'Dashboard'),attrs:{"fill-height":"","wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{attrs:{"color":_vm.$route.name == 'Dashboard' ? 'transparent' : 'white',"flat":_vm.$route.name == 'Dashboard' ? true : true}},[_c('v-layout',{staticClass:"mainbg4",attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('router-view')],1)],1)],1)],1)],1)],1)],1):(_vm.uType === '772')?_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md2":"","lg2":"","xl2":"","hidden-sm-and-down":""}},[_c('v-navigation-drawer',{staticClass:"fixedSidebar",attrs:{"height":"100vh","width":"100%","permanent":"","fixed":"","clipped":"","color":"white"},model:{value:(_vm.sideNav),callback:function ($$v) {_vm.sideNav=$$v},expression:"sideNav"}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-list',_vm._l((_vm.user),function(main){return _c('v-list',{key:main._id},[(main.subMenu)?_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-list-group',{attrs:{"no-action":"","value":false},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm1":"","pl-2":""}},[_c('v-list-item-icon',[_c('v-img',{attrs:{"height":"20px","contain":"","src":main.src}})],1)],1),_c('v-flex',{class:{
                              mainbg6:
                                _vm.$route.path === main.name &&
                                _vm.$route.path === _vm.sub.route,
                            },attrs:{"xs6":"","sm8":"","text-center":"","pl-8":"","pt-5":""}},[_c('v-list-item-title',{staticStyle:{"font-size":"16px","color":"black","cursor":"pointer","font-family":"mainfont"}},[_vm._v(_vm._s(main.name)+" "+_vm._s(_vm.getUserType)+" ")])],1)],1)]},proxy:true}],null,true)},[_vm._l((main.subMenu),function(sub){return [_c('v-flex',{key:sub._id,class:{ mainbg6: _vm.$route.path === sub.route },staticStyle:{"text-decoration":"none"},attrs:{"xs12":"","text-right":"","pl-4":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs6":"","pa-2":"","text-left":""}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":sub.route}},[_c('span',{staticStyle:{"font-size":"15px","color":"black","cursor":"pointer","font-family":"mainfont"},style:(_vm.$route.path == sub.route
                                      ? {
                                          'font-family': 'mainfont',
                                          'font-size': ' 14px',
                                        }
                                      : {
                                          'font-family': 'mainfont',
                                          'font-size': ' 14px',
                                        })},[_vm._v(" "+_vm._s(sub.name)+" ")])])],1)],1)],1),_c('v-flex',{key:sub._d,attrs:{"xs12":""}})]})],2)],1)],1):_c('v-layout',{attrs:{"wrap":"","justify-end":"","pa-1":""}},[_c('v-flex',{class:{ mainbg5: _vm.$route.path === main.route },attrs:{"xs12":""}},[_c('v-list-item-group',{attrs:{"active-class":"activated"}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":main.route}},[_c('v-layout',{staticClass:"content",attrs:{"wrap":"","justify-end":""}},[_c('v-flex',{attrs:{"xs12":"","pr-3":"","sm2":"","align-self-center":"","text-center":""}},[_c('v-img',{attrs:{"height":"20px","contain":"","src":main.src}})],1),_c('v-flex',{attrs:{"xs12":"","sm8":"","pl-2":"","pt-1":"","align-self-center":"","text-left":""}},[_c('span',{staticStyle:{"font-size":"18px","color":"black","cursor":"pointer","font-family":"mainfont"},style:(_vm.$route.path === main.route
                                  ? {
                                      'font-family': 'mainfont',
                                      'font-size': '16px',
                                      color: 'black !important',
                                    }
                                  : {
                                      'font-family': 'mainfont',
                                      'font-size': '16px',
                                    })},[_vm._v(" "+_vm._s(main.name)+" ")])])],1)],1)],1)],1),_c('v-divider')],1)],1)}),1)],1)],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md10":"","lg10":"","xl10":"","pt-lg-0":""}},[_c('v-layout',{style:(_vm.$route.name == 'Dashboard'),attrs:{"fill-height":"","wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{attrs:{"color":_vm.$route.name == 'Dashboard' ? 'transparent' : 'white',"flat":_vm.$route.name == 'Dashboard' ? true : true}},[_c('v-layout',{staticClass:"mainbg4",attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('router-view')],1)],1)],1)],1)],1)],1)],1):(_vm.uType === '773')?_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md2":"","lg2":"","xl2":"","hidden-sm-and-down":""}},[_c('v-navigation-drawer',{staticClass:"fixedSidebar",attrs:{"height":"100vh","width":"100%","permanent":"","fixed":"","clipped":"","color":"white"},model:{value:(_vm.sideNav),callback:function ($$v) {_vm.sideNav=$$v},expression:"sideNav"}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-list',_vm._l((_vm.range),function(main){return _c('v-list',{key:main._id},[(main.subMenu)?_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-list-group',{attrs:{"no-action":"","value":false},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm1":"","pl-2":""}},[_c('v-list-item-icon',[_c('v-img',{attrs:{"height":"20px","contain":"","src":main.src}})],1)],1),_c('v-flex',{class:{
                              mainbg6:
                                _vm.$route.path === main.name &&
                                _vm.$route.path === _vm.sub.route,
                            },attrs:{"xs6":"","sm8":"","text-center":"","pl-8":"","pt-5":""}},[_c('v-list-item-title',{staticStyle:{"font-size":"16px","color":"black","cursor":"pointer","font-family":"mainfont"}},[_vm._v(_vm._s(main.name)+" "+_vm._s(_vm.getUserType)+" ")])],1)],1)]},proxy:true}],null,true)},[_vm._l((main.subMenu),function(sub){return [_c('v-flex',{key:sub._id,class:{ mainbg6: _vm.$route.path === sub.route },staticStyle:{"text-decoration":"none"},attrs:{"xs12":"","text-right":"","pl-4":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs6":"","pa-2":"","text-left":""}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":sub.route}},[_c('span',{staticStyle:{"font-size":"15px","color":"black","cursor":"pointer","font-family":"mainfont"},style:(_vm.$route.path == sub.route
                                      ? {
                                          'font-family': 'mainfont',
                                          'font-size': ' 14px',
                                        }
                                      : {
                                          'font-family': 'mainfont',
                                          'font-size': ' 14px',
                                        })},[_vm._v(" "+_vm._s(sub.name)+" ")])])],1)],1)],1),_c('v-flex',{key:sub._d,attrs:{"xs12":""}})]})],2)],1)],1):_c('v-layout',{attrs:{"wrap":"","justify-end":"","pa-1":""}},[_c('v-flex',{class:{ mainbg5: _vm.$route.path === main.route },attrs:{"xs12":""}},[_c('v-list-item-group',{attrs:{"active-class":"activated"}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":main.route}},[_c('v-layout',{staticClass:"content",attrs:{"wrap":"","justify-end":""}},[_c('v-flex',{attrs:{"xs12":"","pr-3":"","sm2":"","align-self-center":"","text-center":""}},[_c('v-img',{attrs:{"height":"20px","contain":"","src":main.src}})],1),_c('v-flex',{attrs:{"xs12":"","sm8":"","pl-2":"","pt-1":"","align-self-center":"","text-left":""}},[_c('span',{staticStyle:{"font-size":"18px","color":"black","cursor":"pointer","font-family":"mainfont"},style:(_vm.$route.path === main.route
                                  ? {
                                      'font-family': 'mainfont',
                                      'font-size': '16px',
                                      color: 'black !important',
                                    }
                                  : {
                                      'font-family': 'mainfont',
                                      'font-size': '16px',
                                    })},[_vm._v(" "+_vm._s(main.name)+" ")])])],1)],1)],1)],1),_c('v-divider')],1)],1)}),1)],1)],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md10":"","lg10":"","xl10":"","pt-lg-0":""}},[_c('v-layout',{style:(_vm.$route.name == 'Dashboard'),attrs:{"fill-height":"","wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{attrs:{"color":_vm.$route.name == 'Dashboard' ? 'transparent' : 'white',"flat":_vm.$route.name == 'Dashboard' ? true : true}},[_c('v-layout',{staticClass:"mainbg4",attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('router-view')],1)],1)],1)],1)],1)],1)],1):(_vm.uType === '7741')?_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md2":"","lg2":"","xl2":"","hidden-sm-and-down":""}},[_c('v-navigation-drawer',{staticClass:"fixedSidebar",attrs:{"height":"100vh","width":"100%","permanent":"","fixed":"","clipped":"","color":"white"},model:{value:(_vm.sideNav),callback:function ($$v) {_vm.sideNav=$$v},expression:"sideNav"}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-list',_vm._l((_vm.section),function(main){return _c('v-list',{key:main._id},[(main.subMenu)?_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-list-group',{attrs:{"no-action":"","value":false},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm1":"","pl-2":""}},[_c('v-list-item-icon',[_c('v-img',{attrs:{"height":"20px","contain":"","src":main.src}})],1)],1),_c('v-flex',{class:{
                              mainbg6:
                                _vm.$route.path === main.name &&
                                _vm.$route.path === _vm.sub.route,
                            },attrs:{"xs6":"","sm8":"","text-center":"","pl-8":"","pt-5":""}},[_c('v-list-item-title',{staticStyle:{"font-size":"16px","color":"black","cursor":"pointer","font-family":"mainfont"}},[_vm._v(_vm._s(main.name)+" "+_vm._s(_vm.getUserType)+" ")])],1)],1)]},proxy:true}],null,true)},[_vm._l((main.subMenu),function(sub){return [_c('v-flex',{key:sub._id,class:{ mainbg6: _vm.$route.path === sub.route },staticStyle:{"text-decoration":"none"},attrs:{"xs12":"","text-right":"","pl-4":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs6":"","pa-2":"","text-left":""}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":sub.route}},[_c('span',{staticStyle:{"font-size":"15px","color":"black","cursor":"pointer","font-family":"mainfont"},style:(_vm.$route.path == sub.route
                                      ? {
                                          'font-family': 'mainfont',
                                          'font-size': ' 14px',
                                        }
                                      : {
                                          'font-family': 'mainfont',
                                          'font-size': ' 14px',
                                        })},[_vm._v(" "+_vm._s(sub.name)+" ")])])],1)],1)],1),_c('v-flex',{key:sub._d,attrs:{"xs12":""}})]})],2)],1)],1):_c('v-layout',{attrs:{"wrap":"","justify-end":"","pa-1":""}},[_c('v-flex',{class:{ mainbg5: _vm.$route.path === main.route },attrs:{"xs12":""}},[_c('v-list-item-group',{attrs:{"active-class":"activated"}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":main.route}},[_c('v-layout',{staticClass:"content",attrs:{"wrap":"","justify-end":""}},[_c('v-flex',{attrs:{"xs12":"","pr-3":"","sm2":"","align-self-center":"","text-center":""}},[_c('v-img',{attrs:{"height":"20px","contain":"","src":main.src}})],1),_c('v-flex',{attrs:{"xs12":"","sm8":"","pl-2":"","pt-1":"","align-self-center":"","text-left":""}},[_c('span',{staticStyle:{"font-size":"18px","color":"black","cursor":"pointer","font-family":"mainfont"},style:(_vm.$route.path === main.route
                                  ? {
                                      'font-family': 'mainfont',
                                      'font-size': '16px',
                                      color: 'black !important',
                                    }
                                  : {
                                      'font-family': 'mainfont',
                                      'font-size': '16px',
                                    })},[_vm._v(" "+_vm._s(main.name)+" ")])])],1)],1)],1)],1),_c('v-divider')],1)],1)}),1)],1)],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md10":"","lg10":"","xl10":"","pt-lg-0":""}},[_c('v-layout',{style:(_vm.$route.name == 'Dashboard'),attrs:{"fill-height":"","wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{attrs:{"color":_vm.$route.name == 'Dashboard' ? 'transparent' : 'white',"flat":_vm.$route.name == 'Dashboard' ? true : true}},[_c('v-layout',{staticClass:"mainbg4",attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('router-view')],1)],1)],1)],1)],1)],1)],1):(_vm.uType === '7742')?_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md2":"","lg2":"","xl2":"","hidden-sm-and-down":""}},[_c('v-navigation-drawer',{staticClass:"fixedSidebar",attrs:{"height":"100vh","width":"100%","permanent":"","fixed":"","clipped":"","color":"white"},model:{value:(_vm.sideNav),callback:function ($$v) {_vm.sideNav=$$v},expression:"sideNav"}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-list',_vm._l((_vm.superclerk),function(main){return _c('v-list',{key:main._id},[(main.subMenu)?_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-list-group',{attrs:{"no-action":"","value":false},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm1":"","pl-2":""}},[_c('v-list-item-icon',[_c('v-img',{attrs:{"height":"20px","contain":"","src":main.src}})],1)],1),_c('v-flex',{class:{
                              mainbg6:
                                _vm.$route.path === main.name &&
                                _vm.$route.path === _vm.sub.route,
                            },attrs:{"xs6":"","sm8":"","text-center":"","pl-8":"","pt-5":""}},[_c('v-list-item-title',{staticStyle:{"font-size":"16px","color":"black","cursor":"pointer","font-family":"mainfont"}},[_vm._v(_vm._s(main.name)+" "+_vm._s(_vm.getUserType)+" ")])],1)],1)]},proxy:true}],null,true)},[_vm._l((main.subMenu),function(sub){return [_c('v-flex',{key:sub._id,class:{ mainbg6: _vm.$route.path === sub.route },staticStyle:{"text-decoration":"none"},attrs:{"xs12":"","text-right":"","pl-4":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs6":"","pa-2":"","text-left":""}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":sub.route}},[_c('span',{staticStyle:{"font-size":"15px","color":"black","cursor":"pointer","font-family":"mainfont"},style:(_vm.$route.path == sub.route
                                      ? {
                                          'font-family': 'mainfont',
                                          'font-size': ' 14px',
                                        }
                                      : {
                                          'font-family': 'mainfont',
                                          'font-size': ' 14px',
                                        })},[_vm._v(" "+_vm._s(sub.name)+" ")])])],1)],1)],1),_c('v-flex',{key:sub._d,attrs:{"xs12":""}})]})],2)],1)],1):_c('v-layout',{attrs:{"wrap":"","justify-end":"","pa-1":""}},[_c('v-flex',{class:{ mainbg5: _vm.$route.path === main.route },attrs:{"xs12":""}},[_c('v-list-item-group',{attrs:{"active-class":"activated"}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":main.route}},[_c('v-layout',{staticClass:"content",attrs:{"wrap":"","justify-end":""}},[_c('v-flex',{attrs:{"xs12":"","pr-3":"","sm2":"","align-self-center":"","text-center":""}},[_c('v-img',{attrs:{"height":"20px","contain":"","src":main.src}})],1),_c('v-flex',{attrs:{"xs12":"","sm8":"","pl-2":"","pt-1":"","align-self-center":"","text-left":""}},[_c('span',{staticStyle:{"font-size":"18px","color":"black","cursor":"pointer","font-family":"mainfont"},style:(_vm.$route.path === main.route
                                  ? {
                                      'font-family': 'mainfont',
                                      'font-size': '16px',
                                      color: 'black !important',
                                    }
                                  : {
                                      'font-family': 'mainfont',
                                      'font-size': '16px',
                                    })},[_vm._v(" "+_vm._s(main.name)+" ")])])],1)],1)],1)],1),_c('v-divider')],1)],1)}),1)],1)],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md10":"","lg10":"","xl10":"","pt-lg-0":""}},[_c('v-layout',{style:(_vm.$route.name == 'Dashboard'),attrs:{"fill-height":"","wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{attrs:{"color":_vm.$route.name == 'Dashboard' ? 'transparent' : 'white',"flat":_vm.$route.name == 'Dashboard' ? true : true}},[_c('v-layout',{staticClass:"mainbg4",attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('router-view')],1)],1)],1)],1)],1)],1)],1):(_vm.uType === '777')?_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md2":"","lg2":"","xl2":"","hidden-sm-and-down":""}},[_c('v-navigation-drawer',{staticClass:"fixedSidebar",attrs:{"height":"100vh","width":"100%","permanent":"","fixed":"","clipped":"","color":"white"},model:{value:(_vm.sideNav),callback:function ($$v) {_vm.sideNav=$$v},expression:"sideNav"}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-list',_vm._l((_vm.superAdmin),function(main){return _c('v-list',{key:main._id},[(main.subMenu)?_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-list-group',{attrs:{"no-action":"","value":false},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm1":"","pl-2":""}},[_c('v-list-item-icon',[_c('v-img',{attrs:{"height":"20px","contain":"","src":main.src}})],1)],1),_c('v-flex',{class:{
                              mainbg6:
                                _vm.$route.path === main.name &&
                                _vm.$route.path === _vm.sub.route,
                            },attrs:{"xs6":"","sm8":"","text-center":"","pl-8":"","pt-5":""}},[_c('v-list-item-title',{staticStyle:{"font-size":"16px","color":"black","cursor":"pointer","font-family":"mainfont"}},[_vm._v(_vm._s(main.name)+" "+_vm._s(_vm.getUserType)+" ")])],1)],1)]},proxy:true}],null,true)},[_vm._l((main.subMenu),function(sub){return [_c('v-flex',{key:sub._id,class:{ mainbg6: _vm.$route.path === sub.route },staticStyle:{"text-decoration":"none"},attrs:{"xs12":"","text-right":"","pl-4":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs6":"","pa-2":"","text-left":""}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":sub.route}},[_c('span',{staticStyle:{"font-size":"15px","color":"black","cursor":"pointer","font-family":"mainfont"},style:(_vm.$route.path == sub.route
                                      ? {
                                          'font-family': 'mainfont',
                                          'font-size': ' 14px',
                                        }
                                      : {
                                          'font-family': 'mainfont',
                                          'font-size': ' 14px',
                                        })},[_vm._v(" "+_vm._s(sub.name)+" ")])])],1)],1)],1),_c('v-flex',{key:sub._d,attrs:{"xs12":""}})]})],2)],1)],1):_c('v-layout',{attrs:{"wrap":"","justify-end":"","pa-1":""}},[_c('v-flex',{class:{ mainbg5: _vm.$route.path === main.route },attrs:{"xs12":""}},[_c('v-list-item-group',{attrs:{"active-class":"activated"}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":main.route}},[_c('v-layout',{staticClass:"content",attrs:{"wrap":"","justify-end":""}},[_c('v-flex',{attrs:{"xs12":"","pr-3":"","sm2":"","align-self-center":"","text-center":""}},[_c('v-img',{attrs:{"height":"20px","contain":"","src":main.src}})],1),_c('v-flex',{attrs:{"xs12":"","sm8":"","pl-2":"","pt-1":"","align-self-center":"","text-left":""}},[_c('span',{staticStyle:{"font-size":"18px","color":"black","cursor":"pointer","font-family":"mainfont"},style:(_vm.$route.path === main.route
                                  ? {
                                      'font-family': 'mainfont',
                                      'font-size': '16px',
                                      color: 'black !important',
                                    }
                                  : {
                                      'font-family': 'mainfont',
                                      'font-size': '16px',
                                    })},[_vm._v(" "+_vm._s(main.name)+" ")])])],1)],1)],1)],1),_c('v-divider')],1)],1)}),1)],1)],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md10":"","lg10":"","xl10":"","pt-lg-0":""}},[_c('v-layout',{style:(_vm.$route.name == 'Dashboard'),attrs:{"fill-height":"","wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{attrs:{"color":_vm.$route.name == 'Dashboard' ? 'transparent' : 'white',"flat":_vm.$route.name == 'Dashboard' ? true : true}},[_c('v-layout',{staticClass:"mainbg4",attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('router-view')],1)],1)],1)],1)],1)],1)],1):_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md2":"","lg2":"","xl2":"","hidden-sm-and-down":""}},[_c('v-navigation-drawer',{staticClass:"fixedSidebar",attrs:{"height":"100vh","width":"100%","permanent":"","fixed":"","clipped":""},model:{value:(_vm.sideNav),callback:function ($$v) {_vm.sideNav=$$v},expression:"sideNav"}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-list',_vm._l((_vm.division),function(main){return _c('v-list',{key:main._id},[(main.subMenu)?_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-list-group',{attrs:{"no-action":"","value":false},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm1":"","pl-2":""}},[_c('v-list-item-icon',[_c('v-img',{attrs:{"height":"20px","contain":"","src":main.src}})],1)],1),_c('v-flex',{class:{
                              mainbg6:
                                _vm.$route.path === main.name &&
                                _vm.$route.path === _vm.sub.route,
                            },attrs:{"xs6":"","sm8":"","text-center":"","pl-8":"","pt-5":""}},[_c('v-list-item-title',{staticStyle:{"font-size":"16px","color":"black","cursor":"pointer","font-family":"mainfont"}},[_vm._v(_vm._s(main.name)+" ")])],1)],1)]},proxy:true}],null,true)},[_vm._l((main.subMenu),function(sub){return [_c('v-flex',{key:sub._id,class:{ mainbg6: _vm.$route.path === sub.route },staticStyle:{"text-decoration":"none"},attrs:{"xs12":"","text-right":"","pl-4":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs6":"","pa-2":"","text-left":""}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":sub.route}},[_c('span',{staticStyle:{"font-size":"15px","color":"black","cursor":"pointer","font-family":"mainfont"},style:(_vm.$route.path == sub.route
                                      ? {
                                          'font-family': 'mainfont',
                                          'font-size': ' 14px',
                                        }
                                      : {
                                          'font-family': 'mainfont',
                                          'font-size': ' 14px',
                                        })},[_vm._v(" "+_vm._s(sub.name)+" ")])])],1)],1)],1),_c('v-flex',{key:sub._d,attrs:{"xs12":""}})]})],2)],1)],1):_c('v-layout',{attrs:{"wrap":"","justify-end":"","pa-1":""}},[_c('v-flex',{class:{ mainbg5: _vm.$route.path === main.route },attrs:{"xs12":""}},[_c('v-list-item-group',{attrs:{"active-class":"activated"}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":main.route}},[_c('v-layout',{staticClass:"content",attrs:{"wrap":"","justify-end":""}},[_c('v-flex',{attrs:{"xs12":"","pr-3":"","sm2":"","align-self-center":"","text-center":""}},[_c('v-img',{attrs:{"height":"20px","contain":"","src":main.src}})],1),_c('v-flex',{attrs:{"xs12":"","sm8":"","pl-2":"","pt-1":"","align-self-center":"","text-left":""}},[_c('span',{staticStyle:{"font-size":"18px","color":"black","cursor":"pointer","font-family":"mainfont"},style:(_vm.$route.path === main.route
                                  ? {
                                      'font-family': 'mainfont',
                                      'font-size': '16px',
                                      color: 'black !important',
                                    }
                                  : {
                                      'font-family': 'mainfont',
                                      'font-size': '16px',
                                    })},[_vm._v(" "+_vm._s(main.name)+" ")])])],1)],1)],1)],1),_c('v-divider')],1)],1)}),1)],1)],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md10":"","lg10":"","xl10":"","pt-lg-0":""}},[_c('v-layout',{style:(_vm.$route.name == 'Dashboard'),attrs:{"fill-height":"","wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{attrs:{"color":_vm.$route.name == 'Dashboard' ? 'transparent' : 'white',"flat":_vm.$route.name == 'Dashboard' ? true : true}},[_c('v-layout',{staticClass:"mainbg4",attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('router-view')],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }